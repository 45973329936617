<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-row :gutter="40">
        <el-col :span="12">
          <el-form label-position="left" label-width="12rem">
            <el-form-item label="视频倒计时红包倒计时时长">
              <el-input-number v-model="form.video_count_down_award_time"></el-input-number>
              秒
            </el-form-item>
            <el-form-item label="视频倒计时红包奖励金额">
              <el-input-number v-model="form.video_count_down_award_fee"></el-input-number>
              元
            </el-form-item>
            <el-form-item label="视频号ID">
              <el-input v-model="form.mp_video_ac_id"></el-input>
            </el-form-item>
            <el-form-item align="right">
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>

export default {
  name: "edit",
  components: {},
  data() {
    return {
      form: {
        video_count_down_award_time: 0,
        video_count_down_award_fee: 0,
        mp_video_ac_id: "",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.card.plugin.homePage.videoPage.conf().then(res => {
        this.form = res;
      })
    },
    edit() {
      this.$u.api.card.plugin.homePage.videoPage.confEdit(this.form).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>